class BrowserStorage {
  constructor(private readonly storage: Storage) {}

  public getItem(key: string) {
    return JSON.parse(this.storage.getItem(key) || 'null');
  }

  public setItem(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value ?? null));
  }

  public removeItem(key: string) {
    this.storage.removeItem(key);
  }
}

export const sessionStorage = new BrowserStorage(window.sessionStorage);
export const localStorage = new BrowserStorage(window.localStorage);
