angular.module('lwNamb').controller(
  'InviteAddEmailsCtrl',

  [
    '$scope',
    '$log',
    '$localStorage',
    'userService',
    'windowService',
    'inviteService',
    '$routeParams',
    'alertService',
    'searchService',
    'orgService',
    function (
      $scope,
      $log,
      $localStorage,
      userService,
      windowService,
      inviteService,
      $routeParams,
      alertService,
      searchService,
      orgService
    ) {
      var init = function () {
        $localStorage.$sync();
        $scope.submitted = false;
        $scope.showRecipients = true;
        $scope.emailForm = {};
        $scope.recipient = {};

        $scope.orgId = $routeParams.orgId;
        $scope.inviteId = $routeParams.inviteId;
        $scope.settingsId = $routeParams.settingsId;
        $scope.inviteLicenses = $localStorage.inviteLicenses !== undefined ? $localStorage.inviteLicenses : [];
        if ($localStorage.recipients === undefined) {
          $localStorage.recipients = [];
        } else {
          $scope.recipients = $localStorage.recipients;
        }
        if ($localStorage.message) {
          $scope.invite = { message: $localStorage.message };
          $scope.showMessage = true;
        }

        userService.user().then(
          function (user) {
            $scope.user = user;
            if ($scope.inviteId) {
              retrieveUserData();
            }
          },
          function () {
            $log.error('No user returned in InviteAddEmailsCtrl');
          }
        );

        $scope.roles = [];
        $scope.groups = [];
        $scope.training = [];
        $scope.inviteRoles = $localStorage.inviteRoles !== undefined ? $localStorage.inviteRoles : [];
        $scope.inviteGroups = $localStorage.inviteGroups !== undefined ? $localStorage.inviteGroups : [];
        $scope.inviteLicenses = $localStorage.inviteLicenses !== undefined ? $localStorage.inviteLicenses : [];
        $scope.inviteTraining = $localStorage.inviteTraining !== undefined ? $localStorage.inviteTraining : [];

        if ($scope.settingsId) {
          retrieveInviteSettings();
        }
      };

      var retrieveUserData = function () {
        inviteService.getInvite($scope.orgId, $scope.inviteId).then(
          function (response) {
            $scope.invite = response;
            if ($scope.invite && $scope.invite.emails && $scope.invite.emails.length > 0) {
              $scope.invite.message = $scope.invite.emails[$scope.invite.emails.length - 1].body;
              $scope.showMessage = true;
            }
          },
          function (reason) {
            $log.error(reason);
            alertService.show({
              title: 'Error! Something went wrong.',
              type: 'danger',
              show: true,
              dismissible: true,
              duration: 20,
            });
          }
        );
      };

      var retrieveInviteSettings = function () {
        inviteService.getInviteSettings($scope.orgId, $scope.settingsId).then(
          function (response) {
            $scope.inviteSettings = response;
            if (response.trainingIds !== undefined) {
              response.trainingIds.forEach(function (training) {
                searchService.findTraining(training.id).then(
                  function (response) {
                    if (response === undefined) {
                      training.active = false;
                    }
                  },
                  function (reason) {
                    training.active = false;
                  }
                );
              });
            }
            $scope.inviteTraining = response.trainingIds;
            $localStorage.inviteTraining = response.trainingIds;
            response.roles.forEach(function (role) {
              orgService.getRole(role.roleId.id, $scope.orgId).then(function (response) {
                role.active = response.active;
              });
            });
            response.groups.forEach(function (group) {
              orgService.getGroup(group.groupId.id, $scope.orgId).then(
                function (response) {
                  group.active = response.published;
                },
                function (reason) {
                  $log.error('No group found ' + group.groupId.id + ': ' + reason);
                  group.active = false;
                }
              );
            });
            $scope.inviteRoles = response.roles;
            $scope.inviteGroups = response.groups;
            $scope.inviteLicenses = response.licenses;

            $scope.hasOptionalSettings =
              $scope.inviteId &&
              ($scope.inviteRoles || $scope.inviteGroups || $scope.inviteLicenses || $scope.inviteTraining);
          },
          function (reason) {
            $log.error(reason);
            alertService.show({
              title: 'Error! Something went wrong.',
              type: 'danger',
              show: true,
              dismissible: true,
              duration: 20,
            });
          }
        );
      };

      $scope.toggleMessage = function () {
        $scope.showMessage = !$scope.showMessage;
        if (!$scope.showMessage) {
          $scope.invite.message = undefined;
        }
      };

      $scope.addRecipient = function (firstName, lastName, emailAddress, isValid) {
        if (!isValid) {
          $scope.submitted = true;
        } else {
          $scope.duplicateInvitee = false;
          if ($scope.invite !== undefined && $scope.invite.invitees !== undefined) {
            $scope.invite.invitees.find(function (invitee) {
              if (invitee.email === emailAddress) {
                $scope.submitted = true;
                $scope.duplicateInvitee = true;
              }
            });
          }
          if ($scope.duplicateInvitee === false) {
            //Add Recipient to Cache
            $localStorage.recipients.push({
              firstName: firstName,
              lastName: lastName,
              email: emailAddress,
              invitor: { id: $scope.user.userId },
            });
            //Clear Form
            $scope.emailForm.$setPristine();
            $scope.emailForm.$setUntouched();
            $scope.recipient.firstName = '';
            $scope.recipient.lastName = '';
            $scope.recipient.emailAddress = '';
            $scope.submitted = false;
            //Update view
            $scope.recipients = $localStorage.recipients;
          }
        }
      };

      $scope.removeRecipient = function (recipient) {
        $localStorage.recipients.splice($localStorage.recipients.indexOf(recipient), 1);
        $scope.recipients = $localStorage.recipients;
      };

      $scope.previewAndSend = function (message) {
        $localStorage.message = message;
        var additionalParam = $scope.inviteId === undefined ? '' : '/' + $scope.inviteId;
        windowService.redirectHash('/invite/' + $scope.orgId + '/preview-and-send' + additionalParam);
      };

      init();
    },
  ] //End Invite Send Emails Ctrl
);
