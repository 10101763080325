import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import {
  InviteSettings,
  WorkflowInviteEntity,
  transformRoleEntity,
  transformGroupEntity,
  transformTrainingEntity,
} from '../models/inviteSettings';

export const getInviteSettings: (settingsId: string) => Promise<InviteSettings> = settingsId =>
  cacheableRequest(`/v1/workflows/${settingsId}`).then(
    (response: AxiosResponse<{ entities: WorkflowInviteEntity[] }>) =>
      response.data.entities.reduce(
        (settings: InviteSettings, entity: WorkflowInviteEntity) => {
          switch (entity._type) {
            case 'RoleView':
              return { ...settings, roles: [...settings.roles, transformRoleEntity(entity)] };
            case 'GroupView':
              return { ...settings, groups: [...settings.groups, transformGroupEntity(entity)] };
            case 'Assign':
              return { ...settings, trainings: [...settings.trainings, transformTrainingEntity(entity)] };
            default:
              return settings;
          }
        },
        { roles: [], licenses: [], groups: [], trainings: [] } as InviteSettings
      )
  );
