import submitCommand from './submitCommand';
import uuid from './uuid';
import cacheableRequest from './cacheableRequest';

const transformInvites = invites => {
  if (!Array.isArray(invites)) return [];

  return invites.map(invite => {
    const searchData = [];
    if (!invite.membershipCode) {
      invite.invitees?.forEach(invitee => {
        searchData.push(`${invitee.firstName} ${invitee.lastName}`);
        searchData.push(invitee.email);
      });

      return {
        ...invite,
        type: 'email',
        _type: invite._type,
        id: invite._id.id,
        name: invite.name,
        test: invite,
        numInvited: invite?.invitees?.length,
        invitees: invite?.invitees?.map(invitee => ({
          ...invitee,
          fullName: `${invitee.firstName} ${invitee.lastName}`,
          status: invitee.status === 'Removed' ? 'Disabled' : invitee.status,
        })),
        numRedeemed: invite?.invitees?.filter(invitee => invitee.status === 'Accepted').length,
        searchData: [invite.name, ...searchData],
      };
    } else {
      invite.redemptions.forEach(redemption => {
        searchData.push(redemption.email);
        searchData.push(`${redemption.firstName} ${redemption.lastName}`);
      });

      const fullCode = invite.membershipCode.prefix + '-' + invite.membershipCode.suffix;

      return {
        ...invite,
        type: 'code',
        _type: invite._type,
        name: invite.name,
        id: invite._id.id,
        membershipCode: {
          fullCode,
        },
        redemptions: invite?.redemptions?.map(redemption => ({
          ...redemption,
          fullName: `${redemption.firstName} ${redemption.lastName}`,
        })),
        redemptionLink: `${window.location.origin}/#/redeem-invite/${invite.membershipCode.prefix}/${invite.membershipCode.suffix}`,
        numRedeemed: invite.redemptions === undefined ? 0 : invite.redemptions.length,
        searchData: [invite.name, fullCode, ...searchData],
        ...(invite.redemptionLimit && {
          numInvited: invite.redemptionLimit,
        }),
      };
    }
  });
};

const getInvites = inviteId =>
  cacheableRequest(`/v1/invites/${inviteId}`)
    .then(response => {
      return transformInvites(response.data.invites);
    })
    .catch(err => err.response);

const inviteService = {
  getInvites: id => getInvites(id),
  getInvite: (orgId, inviteId) =>
    getInvites(orgId).then(invites => {
      const findInvite = invites.find(invite => invite.id === inviteId);
      if (findInvite) {
        return findInvite;
      } else {
        return Promise.reject('Invite not found');
      }
    }),
  acceptInvite: (userId, orgId, userEmail) => {
    const id = uuid.generate();
    const cmd = {
      id: id,
      userId: { id: userId },
      orgId: { id: orgId },
      forEmail: userEmail,
      redeemingEmail: userEmail,
      initiatingUserId: userId,
    };
    return submitCommand(id, cmd, 'AcceptInvite', 'InviteAccepted', 'AcceptInviteError');
  },
  lookupInvitesForEmail: userEmail =>
    cacheableRequest(`/v1/invites/${userEmail}/lookup`, 0.25).then(
      response => response.data || Promise.reject('Invalid response')
    ),
};

export default inviteService;
