import React from 'react';
import alertService from '../../services/AlertService';
import { deactivateInvite } from '../api/deactivateInvite';
import windowService from '../../services/windowService';
import ConfirmationModal from '../../components/ConfirmationModal';
import { ModalWarningList } from '../../components/ModalElements';
import { useUser } from '../../authentication';
import { handleError } from '../../utils/apiUtils';

type Props = {
  inviteId: string;
  inviteName: string;
  orgId: string;
  dismissModal: () => void;
};

export const DeactivateInviteModal = ({ inviteId, inviteName, orgId, dismissModal, ...props }: Props) => {
  const user = useUser();

  const deactivateInviteAndRedirect = () => {
    deactivateInvite(inviteId, user?.userId || '')
      .then(() => {
        alertService.showOnNextPage('Invitation Deactivated');
        windowService.redirectTo(`invite/${orgId}/code/${inviteId}`);
      })
      .catch(handleError);
  };

  return (
    <ConfirmationModal
      buttonActionText="Deactivate"
      buttonType="danger"
      title="Deactivate Invitation"
      prompt={<span>Are you sure you want to deactivate this invitation?</span>}
      subtext={inviteName}
      handleSubmit={deactivateInviteAndRedirect}
      handleDismiss={dismissModal}
      {...props}
    >
      <ModalWarningList
        warnings={['This invitation will no longer be able to be redeemed', 'This action cannot be undone']}
      />
    </ConfirmationModal>
  );
};
