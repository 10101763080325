import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getInviteSettings } from '../api/getInviteSettings';
import { setOptionsForInvite } from '../api/setOptionsForInvite';
import { Invite } from '../models/invite';
import { InviteGroup, InviteRole, InviteSettings, InviteTraining } from '../models/inviteSettings';
import { getStoredInvite, updateStoredInvite } from '../state/storedInviteState';

type State = {
  isLoading: boolean;
  invite?: Invite;
  inviteSettings: InviteSettings;
  error?: AxiosError;
};

export const useInviteSettings = (inviteId?: string, inviteSettingsId?: string, userId?: string) => {
  const [data, setData] = useState<State>({
    isLoading: true,
    inviteSettings: {
      roles: [],
      licenses: [],
      groups: [],
      trainings: [],
    },
  });

  const updateSetting = useCallback(
    (setting: keyof InviteSettings, value: InviteTraining[] | InviteGroup[] | InviteRole[]) => {
      const updatedSettings = { ...data.inviteSettings, [setting]: value };
      if (inviteId) {
        return setOptionsForInvite(inviteId, inviteSettingsId, updatedSettings, userId).then(() => {
          setData(prev => ({ ...prev, inviteSettings: updatedSettings }));
        });
      }

      updateStoredInvite(invite => ({ ...invite, [setting]: value }));
      setData(prev => ({ ...prev, inviteSettings: updatedSettings }));
      return Promise.resolve();
    },
    [data.inviteSettings, inviteId, inviteSettingsId]
  );

  useEffect(() => {
    if (!inviteId || !inviteSettingsId) {
      const storedInvite = getStoredInvite();
      const storedSettings = {
        roles: storedInvite.roles || [],
        licenses: [],
        groups: (storedInvite.groups as InviteGroup[]) || [],
        trainings: (storedInvite.trainings as InviteTraining[]) || [],
      };

      return setData({ inviteSettings: storedSettings, isLoading: false });
    }

    getInviteSettings(inviteSettingsId)
      .then(inviteSettings => {
        setData({ isLoading: false, inviteSettings });
      })
      .catch(error => {
        console.error(error);
        setData(prev => ({ ...prev, isLoading: false, error }));
      });
  }, []);

  return {
    ...data,
    updateSetting,
  };
};
