import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Border, Breakpoints, Color, Grid, Shadow, Type } from '../StyleGuide';
import { useUser } from '../authentication';
import { RoundedDeemphasizedBadge } from '../components/Badge';
import Breadcrumbs from '../components/Breadcrumbs';
import { PrimaryButton, SmallOutlineButton } from '../components/Buttons';
import EasyLinkModal from '../components/EasyLinkModal';
import EmptyState from '../components/EmptyState';
import ErrorMessage from '../components/ErrorMessage';
import { TextFilterInput } from '../components/FormElements';
import { Container, PageTitle } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ThumbnailIcon from '../components/ThumbnailIcon';
import useModal from '../hooks/useModal';
import AdvancedInvitesAddModal from './AdvancedInvitesAddModal';
import { getInvites } from './api/getInvites';
import { InviteIcon } from './components/InviteIcon';
import { clearStoredInvite } from './state/storedInviteState';

const PageContainer = styled(Container)`
  margin-bottom: ${Grid._14};
`;

const InvitesContainer = styled.div`
  display: grid;
  grid-gap: ${Grid._5};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-gap: ${Grid._4} ${Grid._5};
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${Grid._6};
  margin-bottom: ${Grid._6};

  & > div {
    width: 100%;
  }

  @media screen and (max-width: ${Breakpoints.screen_xs}) {
    gap: ${Grid._4};
    flex-direction: column;
  }
`;

const AddInviteButton = styled(PrimaryButton)`
  width: 260px;
  @media screen and (max-width: ${Breakpoints.screen_xs}) {
    width: 100%;
  }
`;

const Invite = styled.div`
  display: flex;
  background: ${Color.white};
  box-shadow: ${Shadow.medium};
  border-radius: ${Border.radius};
  position: relative;
  min-height: ${Grid._12};
  word-break: break-word;

  h4 {
    margin-top: 0;
  }
  p {
    margin-bottom: 0;
  }

  .icon-label-badge {
    font-size: ${Type.Scale._1};
    background: ${Color.white};
    color: ${Color.Gray._50};
    border: 1px solid ${Color.Gray._50};
  }

  i {
    cursor: pointer;
  }

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._4};
  }
`;

const StyledThumbnailIcon = styled(ThumbnailIcon)`
  margin: ${Grid._4};
  flex-shrink: 0;

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    display: none;
  }
`;

const InviteLink = styled(Link)`
  display: flex;
  width: 100%;
`;

const TextContainer = styled.div`
  opacity: ${props => !props.active && '0.5'};
  width: 100%;
  margin-top: ${Grid._4};

  p {
    text-transform: uppercase;
  }

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    margin-top: 0;
  }
`;

const DeactivatedText = styled.p`
  margin-top: ${Grid._2};
  color: ${Color.Red._70} !important;
`;

const ShareButton = styled(SmallOutlineButton)`
  position: absolute;
  bottom: ${Grid._3};
  right: ${Grid._3};
  padding: ${Grid._2} ${Grid._3};
`;

const RecipientBadge = styled(RoundedDeemphasizedBadge)`
  position: absolute;
  top: ${Grid._3};
  right: ${Grid._3};
`;

const sortByActiveStatus = (a, b) => !!b.active - !!a.active;
const isPendingInviteView = invite => invite._type === 'PendingInviteView';

const AdvancedInvites = () => {
  const user = useUser();
  const [data, setData] = useState({
    invites: [],
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [easyLinkId, setEasyLinkId] = useState('');
  const orgId = user.lastSelectedAccount;
  const { isLoading, isError, invites } = data;
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Advanced Invites' }];

  useEffect(() => {
    getInvites(orgId)
      .then(invites => {
        const filteredInvites = invites.filter(invite => !isPendingInviteView(invite)).toSorted(sortByActiveStatus);
        setData(prevData => ({
          ...prevData,
          invites: filteredInvites,
          isLoading: false,
        }));
      })
      .catch(error => {
        console.error(error);
        setData(prevData => ({ ...prevData, isError: true, isLoading: false }));
      });
    clearStoredInvite();
  }, []);

  const filteredInvites = invites.filter(invite =>
    invite.searchData?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [modal, openModal] = useModal((type, _, dismissModal) => {
    if (!type) return;
    return <AdvancedInvitesAddModal orgId={orgId} handleDismiss={dismissModal} />;
  });

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage />
        ) : (
          <>
            <PageTitle className="mb-40 mt-24">
              <h1>Advanced Invites</h1>
              <p>Create invitations with automated setup tasks</p>
            </PageTitle>

            <SearchContainer>
              <TextFilterInput
                placeholder="Search Invites"
                onChange={e => setSearchQuery(e.target.value)}
                clearInput={() => setSearchQuery('')}
              />
              <AddInviteButton data-qa-hook="inviteAdd" type="button" onClick={() => openModal('addInvites')}>
                Add Advanced Invite
              </AddInviteButton>
            </SearchContainer>

            {!!searchQuery && filteredInvites?.length === 0 ? (
              <EmptyState
                icon="fa-search"
                title="Your search does not have any matches"
                description="Please try another search."
              />
            ) : !searchQuery && filteredInvites?.length === 0 ? (
              <EmptyState
                title="No Advanced Invites"
                description="Use the 'Add Advanced Invite' button above to get started."
              />
            ) : (
              <InvitesContainer data-qa-hook="invitePanelList">
                {filteredInvites.map(invite => (
                  <Invite key={invite.id} data-qa-hook="invitePanel">
                    <InviteLink to={`/invite/${orgId}/${invite.type}/${invite.id}`}>
                      <StyledThumbnailIcon>
                        <InviteIcon invitationType={invite.type} additionalClasses="hidden-xs" />
                      </StyledThumbnailIcon>
                      <TextContainer active={invite.active}>
                        <h4>{invite.name}</h4>
                        <p>{invite.type === 'email' ? 'Email' : invite.active ? invite?.membershipCode : ''}</p>
                        {!invite.active && <DeactivatedText>Deactivated</DeactivatedText>}
                        <RecipientBadge>
                          {invite.redemptionCount} / {invite.redemptionLimit ?? 'Unlimited'}
                        </RecipientBadge>
                      </TextContainer>
                    </InviteLink>
                    {invite.type === 'code' && invite.active && (
                      <ShareButton data-qa-hook="invitePanelShareCode" onClick={() => setEasyLinkId(invite.id)}>
                        <i className="fas fa-share-square"></i> Share
                      </ShareButton>
                    )}
                    {easyLinkId === invite.id && (
                      <EasyLinkModal
                        link={invite.redemptionLink}
                        description={`<h4>Use the <b>${invite.name}</b> Easy Link.</h4>`}
                        handleDismiss={() => setEasyLinkId('')}
                      />
                    )}
                  </Invite>
                ))}
              </InvitesContainer>
            )}
          </>
        )}
      </PageContainer>
      {modal}
    </>
  );
};

export default AdvancedInvites;
