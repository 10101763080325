angular.module('lwNamb').controller(
  'InviteSendEmailsCtrl',

  [
    '$scope',
    '$log',
    '$localStorage',
    'userService',
    'windowService',
    'inviteService',
    '$routeParams',
    '$sce',
    'inviteDataFormatter',
    'alertService',
    function (
      $scope,
      $log,
      $localStorage,
      userService,
      windowService,
      inviteService,
      $routeParams,
      $sce,
      inviteDataFormatter,
      alertService
    ) {
      var init = function () {
        $localStorage.$sync();
        $scope.spinner = false;
        $scope.orgId = $routeParams.orgId;
        $scope.inviteId = $routeParams.inviteId;
        if ($localStorage.recipients === undefined) {
          $localStorage.recipients = [];
        } else {
          $scope.recipients = $localStorage.recipients;
        }

        userService.user().then(
          function (user) {
            $scope.user = user;
            getEmailPreview();
          },
          function () {
            $log.error('No user returned in InviteSendEmailsCtrl');
          }
        );
      };

      function getEmailPreview() {
        if ($localStorage.recipients !== undefined && $localStorage.recipients.length > 0) {
          var emails = [];
          $localStorage.recipients.forEach(function (recipient) {
            emails.push(recipient.email);
          });
          inviteService.getInviteEmailPreview($scope.orgId, emails, $localStorage.message).then(
            function (result) {
              $scope.previewEmails = result;
              $scope.preview = $sce.trustAsHtml($scope.previewEmails.emails[0].content.html);
            },
            function (reason) {
              $log.error(reason);
            }
          );
        }
      }

      $scope.sendEmails = function () {
        $scope.spinner = true;
        //Send Emails
        var data = inviteDataFormatter.get();
        if ($scope.inviteId === undefined) {
          inviteService
            .createEmailInvite(
              $localStorage.invitationName,
              $scope.orgId,
              data.roles,
              data.trainings,
              data.licenses,
              data.groups,
              data.invitees,
              data.email,
              $scope.user.userId
            )
            .then(
              function (response) {
                sendSuccess(response);
              },
              function (reason) {
                $log.error(reason);
                alertService.show({
                  title: 'Error! Something went wrong.',
                  type: 'danger',
                  show: true,
                  dismissible: true,
                  duration: 20,
                });
              }
            );
        } else {
          inviteService
            .addEmailInvitees($scope.inviteId, $scope.orgId, data.invitees, data.email, $scope.user.userId)
            .then(
              function () {
                sendSuccess($scope.inviteId);
              },
              function (reason) {
                $log.error(reason);
                alertService.show({
                  title: 'Error! Something went wrong.',
                  type: 'danger',
                  show: true,
                  dismissible: true,
                  duration: 20,
                });
              }
            );
        }
      };

      var sendSuccess = function(id) {
        try {
          window.cacheService.removeAllInPath('/v1/invites');
        } catch(e) {
          $log.error('Error clearing React advanced invites cache');
        }
        //Clear Cache
        $localStorage.invitationName = undefined;
        $localStorage.inviteRoles = undefined;
        $localStorage.inviteGroups = undefined;
        $localStorage.inviteLicenses = undefined;
        $localStorage.inviteTraining = undefined;
        $localStorage.recipients = undefined;
        $localStorage.message = undefined;
        $scope.spinner = false;
        //Redirect to invite-edit-email.html upon success
        windowService.redirectHash('/invite/' + $scope.orgId + '/email/' + id);
      };

      init();
    },
  ] //End Invite Send Emails Ctrl
);
