import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { OutlineButton, PrimaryButton } from '../../components/Buttons';
import { FormField } from '../../components/FormElements';
import windowService from '../../services/windowService';
import { InviteUrlParams } from '../models/inviteUrlParams';
import {
  emailInviteSchema,
  FormValuesListener,
  getInitialEmailInviteFormValues,
  handleSchemaValidation,
} from '../state/inviteFormState';
import { clearStoredInvite } from '../state/storedInviteState';
import { ButtonGroup, InviteForm, InviteNameFormFieldContainer } from './InviteSettingsElements';

type Props = {
  children?: React.ReactNode;
};

export const EmailSettingsForm = ({ children }: Props) => {
  const { inviteId, orgId } = useParams<InviteUrlParams>();

  const submit = () => {
    windowService.redirectTo(`invite/${orgId}/send-emails`);
  };

  const clearStorageAndRedirectToInvites = () => {
    clearStoredInvite();
    windowService.redirectTo(`invites`);
  };

  return (
    <Formik
      enableReinitialize={!!inviteId}
      initialValues={getInitialEmailInviteFormValues()}
      validate={values =>
        handleSchemaValidation(emailInviteSchema.validate(values, { abortEarly: false, context: { orgId, inviteId } }))
      }
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <InviteForm>
          <FormField
            Wrapper={InviteNameFormFieldContainer}
            placeholder="Enter Invitation Name"
            name="inviteName"
            id="inviteName"
            label="Invitation Name"
          />

          {children}

          <ButtonGroup>
            <div>
              <PrimaryButton operating={isSubmitting} disabled={isSubmitting} type="submit">
                Continue
              </PrimaryButton>
              <OutlineButton onClick={clearStorageAndRedirectToInvites}>Cancel</OutlineButton>
            </div>
          </ButtonGroup>
          <FormValuesListener />
        </InviteForm>
      )}
    </Formik>
  );
};
