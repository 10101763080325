import submitCommand from '../../services/submitCommand';

export const formatExpirationDate = (date: Date) => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  dayOfMonth: date.getDate(),
  timeZone: 0,
});

type FormattedDate = {
  year: number;
  month: number;
  dayOfMonth: number;
  timeZone: number;
};

type SetExpirationDateRequest = {
  id: string;
  initiatingUserId: string;
  date?: FormattedDate;
};

export const setNoExpirationDate = (inviteId: string, initiatingUserId: string) =>
  updateExpirationDate({ id: inviteId, initiatingUserId });
export const setExpirationDate = (inviteId: string, expirationDate: Date, initiatingUserId: string) =>
  updateExpirationDate({ id: inviteId, date: formatExpirationDate(expirationDate), initiatingUserId });

export const updateExpirationDate = (requestBody: SetExpirationDateRequest) =>
  submitCommand(
    requestBody.id,
    requestBody,
    'SetMembershipCodeExpirationDate',
    'MembershipCodeExpirationDateSet',
    'MembershipCodeError'
  );
