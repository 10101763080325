import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import { localStorage } from '../../utils/storageUtils';
import { getInvites } from '../api/getInvites';
import { setOptionsForInvite } from '../api/setOptionsForInvite';
import { updateEasyLinkInviteName } from '../api/updateInviteName';
import { setExpirationDate, setNoExpirationDate } from '../api/updateInviteExpirationDate';
import { setRedemptionLimit, setRedemptionLimitToUnlimited } from '../api/updateInviteRedemptionLimit';
import { Invite } from '../models/invite';
import { EasyLinkFormValues, InviteFormValues } from '../models/inviteForm';
import { InviteSettings, InviteTraining } from '../models/inviteSettings';
import { getStoredInvite, updateStoredInvite } from './storedInviteState';

const inviteNameValidationSchema = Yup.string()
  .max(64, 'Name must be 64 characters or less.')
  .required('Name is required')
  .test('uniqueName', 'That invitation name is already taken', (inviteName, { options }) => {
    const { orgId, inviteId } = options.context as { orgId: string; inviteId: string };

    if (!orgId) return false;
    return getInvites(orgId).then(
      (invites: Invite[]) => !invites.some(invite => invite.id !== inviteId && invite.name === inviteName)
    );
  });

export const emailInviteSchema = Yup.object().shape({ inviteName: inviteNameValidationSchema });

export const easyLinkInviteSchema = Yup.object().shape({
  inviteName: inviteNameValidationSchema,
  redemptionLimit: Yup.number().when('hasRedemptionLimit', {
    is: true,
    then: Yup.number().required('Must be 1 or more').integer('Must be a number').min(1, 'Must be 1 or more'),
  }),
});

export const handleSchemaValidation = (schemaValidator: Promise<unknown>) =>
  schemaValidator
    .then(() => {})
    .catch((validationError: Yup.ValidationError) => {
      if (!validationError?.inner?.length) return {};
      return Object.fromEntries(
        validationError.inner.map((error: Yup.ValidationError) => [error.path, error.errors[0]])
      );
    });

export const getInitialEmailInviteFormValues = () => {
  const stored = getStoredInvite() || {};
  return {
    inviteName: '',
    roles: [],
    groups: [],
    trainings: [],
    ...stored,
  };
};

export const getInitialEasyLinkInviteFormValues = () => {
  const stored = getStoredInvite() || {};

  return {
    inviteName: '',
    redemptionLimit: 1,
    hasRedemptionLimit: false,
    hasExpirationDate: false,
    roles: [],
    groups: [],
    trainings: [],
    // override the defaults with the stored invite
    ...stored,
    // convert the expiration date (stored or default) to a Date object
    expirationDate: stored.expirationDate ? new Date(stored.expirationDate) : new Date(),
  };
};

export const FormValuesListener = () => {
  const debounce = useDebounceCallback(1000);
  const { values } = useFormikContext<InviteFormValues>();

  const updateStoredValues = (values: InviteFormValues) => {
    updateStoredInvite(() => values);

    // TODO: remove when the Email pages are converted to React
    localStorage.setItem('ngStorage-inviteRoles', values.roles);
    localStorage.setItem('ngStorage-inviteGroups', values.groups);
    localStorage.setItem('ngStorage-inviteTraining', values.trainings);
    localStorage.setItem('ngStorage-invitationName', values.inviteName);
  };

  useEffect(() => {
    debounce(() => updateStoredValues(values));

    return () => {
      updateStoredValues(values);
      debounce(null);
    };
  }, [values]);

  return null;
};

export const getUpdateRequests = (
  values: EasyLinkFormValues,
  initialValues: EasyLinkFormValues,
  inviteId: string,
  settingsId?: string,
  userId?: string
) => {
  const updateRequests = [];
  if (values.inviteName !== initialValues.inviteName) {
    updateRequests.push(updateEasyLinkInviteName(inviteId, values.inviteName, userId || ''));
  }

  if (!values.hasRedemptionLimit && values.hasRedemptionLimit !== initialValues.hasRedemptionLimit) {
    updateRequests.push(setRedemptionLimitToUnlimited(inviteId, userId || ''));
  }

  if (values.hasRedemptionLimit && values.redemptionLimit && values.redemptionLimit !== initialValues.redemptionLimit) {
    updateRequests.push(setRedemptionLimit(inviteId, values.redemptionLimit, userId || ''));
  }

  if (!values.hasExpirationDate && values.hasExpirationDate !== initialValues.hasExpirationDate) {
    updateRequests.push(setNoExpirationDate(inviteId, userId || ''));
  }

  if (values.hasExpirationDate && values.expirationDate && values.expirationDate !== initialValues.expirationDate) {
    updateRequests.push(setExpirationDate(inviteId, values.expirationDate, userId || ''));
  }

  if (
    initialValues.trainings.filter((it: InviteTraining) => !values.trainings.find(vt => vt.id === it.id)).length > 0
  ) {
    const { roles, groups, trainings } = values;
    updateRequests.push(setOptionsForInvite(inviteId, settingsId, { roles, groups, trainings } as InviteSettings));
  }

  return updateRequests.length > 0 ? updateRequests : [Promise.resolve()];
};
