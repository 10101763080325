angular.module('lwNamb').controller(
  'inviteSettingsCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    'alertService',
    '$localStorage',
    'userService',
    'windowService',
    'searchService',
    'orgService',
    'licenseService',
    'inviteService',
    'inviteDataFormatter',
    function (
      $scope,
      $routeParams,
      $log,
      alertService,
      $localStorage,
      userService,
      windowService,
      searchService,
      orgService,
      licenseService,
      inviteService,
      inviteDataFormatter
    ) {
      var init = function () {
        $localStorage.$sync();
        $scope.type = $routeParams.type;
        $scope.inviteId = $routeParams.inviteId;
        $scope.selectedItems = [];
        $scope.submitted = false;
        $scope.duplicateNameError = false;
        $scope.showMessage = false;

        //Sample MC Generic Data
        $scope.numberInvites = false;
        $scope.unlimited = true;
        $scope.number = 1;
        $scope.isExpirationDateSet = false;
        $scope.selectedDate = new Date();

        $scope.orgId = $routeParams.orgId;
        $scope.inviteId = $routeParams.inviteId;
        $scope.settingsId = $routeParams.settingsId;

        $scope.sortBy = 'name';
        $scope.orderBy = 'false';

        userService.user().then(
          function (user) {
            $scope.user = user;
            //If Invite already Exists - Retrieve Settings data - Else set up local storage for a new invitation
            if ($scope.inviteId) {
              $scope.loading = false;
              retrieveUserData();
            } else {
              $localStorage.$sync();

              $scope.loading = true;
              $scope.invitationName = '';
              $scope.roles = [];
              $scope.groups = [];
              $scope.training = [];
              $scope.inviteRoles = $localStorage.inviteRoles !== undefined ? $localStorage.inviteRoles : [];
              $scope.inviteGroups = $localStorage.inviteGroups !== undefined ? $localStorage.inviteGroups : [];
              $scope.inviteLicenses = $localStorage.inviteLicenses !== undefined ? $localStorage.inviteLicenses : [];
              $scope.inviteTraining = $localStorage.inviteTraining !== undefined ? $localStorage.inviteTraining : [];
              $scope.unlimited = $localStorage.unlimited !== undefined ? $localStorage.unlimited : $scope.unlimited;
              $scope.number = $localStorage.redemptionLimit !== undefined ? $localStorage.redemptionLimit : 1;
              $scope.isExpirationDateSet =
                $localStorage.isExpirationDateSet !== undefined
                  ? $localStorage.isExpirationDateSet
                  : $scope.isExpirationDateSet;
              $scope.selectedDate =
                $localStorage.expirationDate !== undefined ? $localStorage.expirationDate : $scope.selectedDate;
              $scope.invitationName = $localStorage.invitationName !== undefined ? $localStorage.invitationName : '';
              //Check to see if local storage already exists
              var isRolesUndefined = $localStorage.inviteRoles === undefined;
              var isTrainingUndefined = $localStorage.inviteTraining === undefined;
              var isLicenseUndefined = $localStorage.inviteLicenses === undefined;
              var isGroupsUndefined = $localStorage.inviteGroups === undefined;

              if (isRolesUndefined && isTrainingUndefined && isLicenseUndefined && isGroupsUndefined) {
                $localStorage.unlimited = true;
                $localStorage.redemptionLimit = $scope.number;
              }
              if (isRolesUndefined) {
                $localStorage.inviteRoles = [];
              }
              if (isTrainingUndefined) {
                $localStorage.inviteTraining = [];
              }
              if (isLicenseUndefined) {
                $localStorage.inviteLicenses = [];
              }
              if (isGroupsUndefined) {
                $localStorage.inviteGroups = [];
              }

              retrieveAvailableItemsForInvite();
            }
          },
          function () {
            $log.error('No user returned in inviteSettingsCtrl');
          }
        );
      };

      $scope.$watch('invitationName', function (name) {
        if (name !== undefined) {
          var foundDupe = false;
          inviteService.getInvites($scope.orgId).then(
            function (results) {
              results.forEach(function (result) {
                if (result.name === name && result.id !== $scope.inviteId) {
                  foundDupe = true;
                }
              });
              $scope.duplicateNameError = foundDupe;
              if (!foundDupe) {
                $localStorage.invitationName = name;
              }
            },
            function (reason) {
              $log.error('unable to validate invite name: ' + reason);
            }
          );
        }
      });

      var sortRoleObjByName = function (a, b) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        if (a === b) return 0;
        if (a > b) return 1;
        return -1;
      };

      var retrieveAvailableItemsForInvite = function () {
        licenseService.getPairedLicenses($scope.user.lastSelectedAccount, true).then(
          function (response) {
            $scope.licenses = response;
            //Remove already selected invite licenses from attach licenses page
            $scope.licenses.forEach(function (license, index) {
              for (var i = 0; i < $scope.inviteLicenses.length; i++) {
                if ($scope.inviteLicenses[i].id === license.id) {
                  $scope.licenses.splice(index, 1);
                }
              }
            });
            $scope.loading = false;
          },
          function (reason) {
            $scope.loading = false;
            $log.error('Failed to Retrieve Licenses:' + reason);
          }
        );

        orgService.getOrg($scope.user.lastSelectedAccount).then(
          function (org) {
            $scope.org = org;
            org.roles.sort(sortRoleObjByName).forEach(function (role) {
              $scope.roles.push(role);
            });
            //Remove already selected invite roles from attach roles page
            $scope.roles = $scope.roles.filter(function (role) {
              var filterNot = true;
              $scope.inviteRoles.forEach(function (inviteRole) {
                if (inviteRole.id && role.id && inviteRole.id.id === role.id.id) {
                  filterNot = false;
                }
              });
              return filterNot;
            });
            org.groups.sort(sortRoleObjByName).forEach(function (group) {
              $scope.groups.push(group);
            });
            $scope.groups = $scope.groups.filter(function (group) {
              var filterNot = true;
              $scope.inviteGroups.forEach(function (inviteGroup) {
                if (inviteGroup.id && group.id && inviteGroup.id.id === group.id.id) {
                  filterNot = false;
                }
              });
              return filterNot;
            });
            $scope.loading = false;
          },
          function (response) {
            $log.error('Error: Org not found; ' + response);
            $scope.loading = false;
          }
        );
        $scope.loading = false;
      };

      var retrieveUserData = function () {
        inviteService.getInvite($scope.orgId, $scope.inviteId).then(
          function (response) {
            $scope.invitationName = response.name;
            if (response.redemptionLimit !== undefined) {
              $scope.redemptionLimit = $localStorage.redemptionLimit = response.redemptionLimit;
              $scope.unlimited = $localStorage.unlimited = false;
            }
            if (response.numInvited !== undefined) {
              $scope.unlimited = false;
              $scope.number = response.numInvited;
            }
            if (response.expirationDate) {
              $scope.isExpirationDateSet = true;
              $scope.selectedDate = response.expirationDate;
            }
            $scope.active = response.active;
          },
          function (reason) {
            $log.error(reason);
            alertService.show({
              title: 'Error! Something went wrong.',
              type: 'danger',
              show: true,
              dismissible: true,
              duration: 20,
            });
          }
        );
        if ($scope.settingsId !== undefined) {
          inviteService.getInviteSettings($scope.orgId, $scope.settingsId).then(
            function (response) {
              $scope.inviteSettings = response;
              if (response.trainingIds !== undefined) {
                response.trainingIds.forEach(function (training) {
                  searchService.findTraining(training.trainingId).then(
                    function (response) {
                      if (response === undefined) {
                        training.active = false;
                      }
                    },
                    function (reason) {
                      training.active = false;
                    }
                  );
                });
              }
              $scope.inviteTraining = response.trainingIds;
              $localStorage.inviteTraining = response.trainingIds;
              response.roles.forEach(function (role) {
                orgService.getRole(role.roleId.id, $scope.orgId).then(function (response) {
                  role.active = response.active;
                });
              });
              response.groups.forEach(function (group) {
                orgService.getGroup(group.groupId.id, $scope.orgId).then(
                  function (response) {
                    group.active = response.published;
                  },
                  function (reason) {
                    $log.error('No group found ' + group.groupId.id + ': ' + reason);
                    group.active = false;
                  }
                );
              });
              $scope.inviteRoles = response.roles;
              $scope.inviteGroups = response.groups;
              $scope.inviteLicenses = response.licenses;
            },
            function (reason) {
              $log.error(reason);
              alertService.show({
                title: 'Error! Something went wrong.',
                type: 'danger',
                show: true,
                dismissible: true,
                duration: 20,
              });
            }
          );
        }
      };

      $scope.saveSettings = function (isValid, dirty) {
        $scope.submitted = true;
        if ((isValid && !$scope.unlimited && dirty) || ($scope.unlimited && dirty)) {
          $scope.isSaving = true;
          //Update Settings
          var data = inviteDataFormatter.get();
          inviteService
            .saveMemCodeSettings($scope.inviteId, data.limit, data.date, data.invitationName, $scope.user.userId)
            .then(
              function () {
                windowService.redirectHash('#/invite/' + $scope.orgId + '/code/' + $scope.inviteId);
              },
              function (reason) {
                $log.error(reason);
                $scope.isSaving = false;
                showAlert('danger', 'Error! Please try again.');
              }
            );
        } else if (!dirty) {
          windowService.redirectHash('#/invite/' + $scope.orgId + '/code/' + $scope.inviteId);
        }
      };

      $scope.activateInvitation = function () {
        $scope.activating = true;
        inviteService.activateMembershipCode($scope.inviteId, $scope.user.userId).then(
          function () {
            success(undefined, 'Invitation Activated', $scope.activating);
          },
          function (reason) {
            failure(undefined, reason, $scope.activating);
          }
        );
      };

      var success = function (action, msg, toggle, redirect) {
        inviteDataFormatter.clear();
        if (action !== undefined) action();
        showAlert('success', msg, 5);
        toggle = false;
        if (redirect === true) {
          windowService.redirectHash('#/invite/' + $scope.orgId + '/' + $scope.type + '/' + $scope.inviteId);
        } else {
          init();
        }
      };

      var failure = function (action, reason, toggle) {
        if (action !== undefined) action();
        showAlert('danger', 'Error! Please try again.');
        $log.error(reason);
        toggle = false;
      };

      $scope.deactivateInvitation = function (closeModal) {
        //Send command to deactivate Invitation
        //Close Modal after success/failure
        $scope.deactivating = true;

        inviteService.deactivateInvitation($scope.inviteId, $scope.user.userId, $scope.type).then(
          function () {
            success(closeModal, 'Invitation Deactivated', $scope.deactivating, true);
          },
          function (reason) {
            failure(closeModal, reason, $scope.deactivating);
          }
        );
      };

      $scope.attachInviteRoles = function (item) {
        item.active = true;
        $localStorage.inviteRoles.push(item);
        $localStorage.$apply();
        windowService.redirectHash('invite-settings/' + $scope.orgId + '/' + $scope.type);
        $log.info('Invite Roles: ', $localStorage.inviteRoles);
      };

      $scope.attachInviteGroups = function (item) {
        item.active = true;
        $localStorage.inviteGroups.push(item);
        $localStorage.$apply();
        windowService.redirectHash('invite-settings/' + $scope.orgId + '/' + $scope.type);
        $log.info('Invite Groups: ', $localStorage.inviteGroups);
      };

      $scope.attachInviteLicenses = function (item) {
        item.orgId = $scope.orgId;
        $localStorage.inviteLicenses.push(item);
        windowService.redirectHash('invite-settings/' + $scope.orgId + '/' + $scope.type);
        $log.info('Invite Licenses: ', $localStorage.inviteLicenses);
      };

      $scope.attachInviteTraining = function () {
        var data = inviteDataFormatter.get();
        var roles = [],
          licenses = [],
          groups = [];
        if ($scope.inviteRoles !== undefined && $scope.inviteRoles.length > 0) {
          $scope.inviteRoles.forEach(function (role) {
            var roleIdObj = role.roleId !== undefined ? role.roleId : { id: role.id };
            roles.push({
              id: roleIdObj,
              name: role.name,
            });
          });
        }
        if ($scope.inviteGroups !== undefined && $scope.inviteGroups.length > 0) {
          $scope.inviteGroups.forEach(function (group) {
            var groupIdObj = group.groupId !== undefined ? group.groupId : { id: group.id };
            groups.push({
              id: groupIdObj,
              name: group.name,
            });
          });
        }
        if ($scope.inviteLicenses !== undefined && $scope.inviteLicenses.length > 0) {
          $scope.inviteLicenses.forEach(function (license) {
            licenses.push({
              id: { id: license.licenseId.id },
              name: license.name,
              managerId: license.managerId,
            });
          });
        }
        $localStorage.$apply();

        if ($scope.inviteId === undefined) {
          $scope.redirectToSettings();
        } else {
          inviteService
            .setMemCodeOptions(
              $scope.inviteId,
              data.trainings,
              roles,
              licenses,
              groups,
              $scope.user.userId,
              $scope.settingsId
            )
            .then(
              function (event) {
                $scope.isSaving = false;
                $scope.settingsId = event.id;
                $scope.redirectToSettings();
              },
              function (reason) {
                $log.error(reason);
                $scope.isSaving = false;
                showAlert('danger', 'Error! Please try again.');
              }
            );
        }
      };

      $scope.addAndAttachInviteTraining = function (items) {
        items.forEach(function (item) {
          if (item._type === undefined) {
            item._type = item.trainingType;
          }
          item.trainingId = item.id;
          item.active = true;
          if ($localStorage.inviteTraining === undefined) {
            $localStorage.inviteTraining = [];
          }
          $localStorage.inviteTraining.push(item);
        });
        $localStorage.$apply();
        $scope.attachInviteTraining($localStorage.inviteTraining);
      };

      $scope.removeInviteRole = function (index) {
        $localStorage.inviteRoles.splice(index, 1);
      };

      $scope.removeInviteLicense = function (index) {
        $localStorage.inviteLicenses.splice(index, 1);
      };

      $scope.removeInviteGroup = function (index) {
        $localStorage.inviteGroups.splice(index, 1);
      };

      $scope.removeInviteTraining = function (index) {
        $localStorage.inviteTraining.splice(index, 1);
        $scope.inviteTraining = $localStorage.inviteTraining;
        $localStorage.searchQueryString = undefined;
        $localStorage.selectedItems = undefined;
      };

      $scope.removeInviteTrainingAndSave = function (index, closeModal) {
        $scope.isSaving = true;
        $localStorage.inviteTraining.splice(index, 1);
        $scope.attachInviteTraining($localStorage.inviteTraining);
        closeModal();
      };

      $scope.setDueDate = function (selectedDate) {
        $localStorage.expirationDate = selectedDate;
      };

      $scope.setLimit = function (number) {
        $localStorage.redemptionLimit = number;
      };

      $scope.setUnlimited = function (unlimited) {
        $scope.unlimited = unlimited;
        $localStorage.unlimited = unlimited;
      };

      $scope.setExpirationDate = function (set) {
        $scope.isExpirationDateSet = set;
        $localStorage.isExpirationDateSet = set;
      };

      $scope.validateAndRoute = function (isValid) {
        $scope.submitted = true;
        var isValidAndUnique = isValid && !$scope.duplicateNameError;
        $localStorage.invitationName = $scope.invitationName;
        if (isValidAndUnique && $scope.type === 'email') {
          //Create Invitation and redirect
          //Add inviteId to route when wiring
          windowService.redirectHash('/invite/' + $scope.orgId + '/send-emails');
        }
        if (isValidAndUnique && $scope.type === 'code') {
          //Create Invitation and redirect
          var data = inviteDataFormatter.get();
          inviteService
            .createMembershipCode(
              data.invitationName,
              $scope.orgId,
              data.roles,
              data.trainings,
              data.licenses,
              data.groups,
              data.limit,
              data.date,
              $scope.user.userId
            )
            .then(
              function(id) {
                try { window.cacheService.remove('/v1/invites/' + $scope.orgId) } catch (e) {/**/}
                //Add inviteId to route when wiring
                inviteDataFormatter.clear();
                windowService.redirectHash('/invite/' + $scope.orgId + '/view-code/' + id);
              },
              function (reason) {
                $log.error('Failed to create membership code: ' + reason);
              }
            );
        }
      };

      $scope.saveNameAndRedirect = function (redirectPath) {
        $localStorage.invitationName = $scope.invitationName;
        windowService.redirectHash(redirectPath);
      };

      $scope.saveNameAndRedirectV2 = function (path) {
        $localStorage.invitationName = $scope.invitationName;
        if (path === 'select-training') {
          windowService.redirectHash('#/invite/' + $scope.orgId + '/' + $scope.type + getExtraPath() + '/add-training');
        } else {
          $scope.redirectToInvite(path);

        }
      };

      function getExtraPath() {
        var extra = '';
        if ($scope.inviteId !== undefined) {
          extra = extra + '/' + $scope.inviteId;
        }
        if ($scope.settingsId !== undefined) {
          extra = extra + '/' + $scope.settingsId;
        }
        return extra;
      }

      $scope.redirectToInvite = function (path) {
        windowService.redirectHash('#/invite/' + $scope.orgId + '/' + path + '/' + $scope.type + getExtraPath());
      };

      $scope.redirectToSettings = function () {
        windowService.redirectHash('#/invite-settings/' + $scope.orgId + '/' + $scope.type + getExtraPath());
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, duration: duration });
      }

      $scope.$on('$locationChangeStart', function (event, current, previous) {
        if (current.indexOf('select') > -1) {
          $localStorage.selectTrainingBackHash = previous.split('#')[1];
        }
      });

      init();
    },
  ] //End Invite Settings Ctrl
);
