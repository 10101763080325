import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import { MediumOutlineButton, MediumPrimaryButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { Input } from '../components/FormElements';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import windowService from '../services/windowService';
import { Border, Color, Grid, Shadow } from '../StyleGuide';
import { useInvite } from './hooks/useInvite';
import { EasyLinkInvite } from './models/invite';
import { InviteUrlParams } from './models/inviteUrlParams';

const EasyLinkContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
`;

const EasyLinkCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border-radius: ${Border.radius};
  box-shadow: ${Shadow.small};
  background: ${Color.white};
  padding: ${Grid._5};
  margin-top: ${Grid._9};
  max-width: 760px;
`;

const Description = styled.p`
  font-size: 18px;
  color: #555;
  text-align: center;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${Grid._6};
`;

const AdvancedInvitesEasyLink = () => {
  const { orgId, inviteId } = useParams<InviteUrlParams>();
  const { invite, isLoading, error } = useInvite<EasyLinkInvite>(inviteId || '', orgId, false);
  const [copied, setCopied] = useState(false);

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    { name: 'Easy Link' },
  ];

  useEffect(() => {
    if (!copied) return;

    const timeoutId = setTimeout(() => setCopied(false), 1500);
    return () => clearTimeout(timeoutId);
  }, [copied]);

  const redirectToInvitePage = () => {
    windowService.redirectTo(`/invite/${orgId}/code/${inviteId}`);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <EasyLinkContainer>
        <EasyLinkCard>
          <h2>Easy Link Generated</h2>
          <Description>
            You have successfully created an Easy Link for your organization. Copy and paste this Easy Link into an
            email, web site, or social media post.
          </Description>

          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              {error || !invite ? (
                <ErrorMessage>
                  A problem occurred generating the Easy Link. Please refresh the page to try again.
                </ErrorMessage>
              ) : (
                <Input value={invite.redemptionLink} readOnly />
              )}
              <ButtonRow>
                {invite && (
                  <CopyToClipboard text={invite.redemptionLink} onCopy={() => setCopied(true)}>
                    <MediumPrimaryButton disabled={copied}>{copied ? 'Copied' : 'Copy Easy Link'}</MediumPrimaryButton>
                  </CopyToClipboard>
                )}
                <MediumOutlineButton onClick={redirectToInvitePage}>View Invitation</MediumOutlineButton>
              </ButtonRow>
            </>
          )}
        </EasyLinkCard>
      </EasyLinkContainer>
    </>
  );
};

export default AdvancedInvitesEasyLink;
