import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { ActionRowButton, SmallOutlineButton, SmallRoundedIconButton } from '../../components/Buttons';
import EmptyState from '../../components/EmptyState';
import SectionHeader from '../../components/SectionHeader';
import { Breakpoints, Grid } from '../../StyleGuide';
import { InviteGroup, InviteRole, InviteTraining } from '../models/inviteSettings';

const Section = styled.div`
  width: 100%;
  margin-bottom: ${Grid._8};

  .empty-state-ui {
    margin: ${Grid._6} ${Grid._5};
  }
`;

const AttachedElements = styled.div`
  display: grid;
  gap: ${Grid._3};
  margin-bottom: ${Grid._5};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

type InviteSetting = InviteGroup | InviteRole | InviteTraining;

type Props = {
  title: string;
  valueKey: string;
  canEdit: boolean;
  redirectToAdd: () => void;
};

export const SettingSection = ({ title, valueKey, canEdit, redirectToAdd }: Props) => {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <Section>
      <SectionHeader title={title}>
        {canEdit && <SmallOutlineButton onClick={redirectToAdd}>Add {title}</SmallOutlineButton>}
      </SectionHeader>
      {values[valueKey].length === 0 ? (
        <EmptyState
          title={`No ${title} Added`}
          description={`This invite will not automatically assign users to ${title.toLowerCase()}`}
        >
          {canEdit && (
            <p className="text-center">
              Use the "Add {title}" button to attach {title.toLowerCase()} to this invite
            </p>
          )}
        </EmptyState>
      ) : (
        <AttachedElements>
          {values[valueKey].map((setting: InviteSetting) => (
            <ActionRowButton key={setting.name}>
              <h5>{setting.name}</h5>
              {canEdit && (
                <SmallRoundedIconButton
                  onClick={() =>
                    setFieldValue(
                      valueKey,
                      values[valueKey].filter((s: InviteSetting) => s.id !== setting.id)
                    )
                  }
                  aria-label="remove"
                >
                  <i className="far fa-trash-alt"></i>
                </SmallRoundedIconButton>
              )}
            </ActionRowButton>
          ))}
        </AttachedElements>
      )}
    </Section>
  );
};
