import uuid from '../../services/uuid';
import submitCommand from '../../services/submitCommand';
import { InviteGroup, InviteRole, InviteSettings, InviteTraining } from '../models/inviteSettings';

export const formatExpirationDate = (date: Date) => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  dayOfMonth: date.getDate(),
  timeZone: 0,
});

type FormattedDate = {
  year: number;
  month: number;
  dayOfMonth: number;
  timeZone: number;
};

type CreateMembershipCodeRequest = {
  id: string;
  name: string;
  orgId: {
    id: string;
  };
  invitor: {
    id: string;
  };
  initiatingUserId: string;
  inviteOptions?: {
    trainings?: InviteTraining[];
    groups?: InviteGroup[];
    roles?: InviteRole[];
  };
  redemptionLimit?: number;
  expirationDate?: FormattedDate;
};

export const createEasyLinkInvite = (
  inviteName: string,
  orgId: string,
  userId: string,
  settings: InviteSettings,
  redemptionLimit: number | undefined = undefined,
  expirationDate: Date | undefined = undefined
) => {
  const id = uuid.generate();

  const cmd = {
    id: id,
    name: inviteName,
    orgId: {
      id: orgId,
    },
    invitor: {
      id: userId,
    },
    inviteOptions: settings,
    initiatingUserId: userId,
  } as CreateMembershipCodeRequest;

  if (redemptionLimit) {
    cmd['redemptionLimit'] = redemptionLimit;
  }

  if (expirationDate) {
    cmd['expirationDate'] = formatExpirationDate(expirationDate);
  }

  return submitCommand(id, cmd, 'CreateMembershipCodeInvite', 'MembershipCodeInviteCreated', 'InviteError');
};
