import React, { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import { PrimaryButton, TileButton, WhiteButton } from '../components/Buttons';
import EasyLinkModal from '../components/EasyLinkModal';
import EmptyState from '../components/EmptyState';
import ErrorMessage from '../components/ErrorMessage';
import { Label, Select, TextFilterInput } from '../components/FormElements';
import { InlineList } from '../components/Layout';
import LoadingIndicator from '../components/LoadingIndicator';
import SettingsHeader from '../components/SettingsHeader';
import useModal from '../hooks/useModal';
import windowService from '../services/windowService';
import { PageContainer } from './components/InviteElements';
import { InviteIcon } from './components/InviteIcon';
import { RecipientList, RecipientListItem } from './components/RecipientList';
import { HeaderRow, OptionsContainer, SelectContainer, TitleContainer } from './components/ViewInviteElements';
import { useViewInvite } from './hooks/useViewInvite';
import {
  EasyLinkInvite,
  InvitationTypeDisplayNameMap,
  Invitee,
  Redemption,
  isEasyLinkInvite,
  isEmailInvite,
} from './models/invite';
import { InviteUrlParams } from './models/inviteUrlParams';
import { Statuses } from './models/status';

export const ViewInvite = () => {
  const { inviteType, inviteId, orgId } = useParams<InviteUrlParams>();
  const { invite, isLoading, error, recipients, filteredRecipients, filters } = useViewInvite(inviteId || '', orgId);

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    { name: `${invite?.name || ''}` },
  ];

  const redirectToEasyLinkSettings = () =>
    invite &&
    windowService.redirectTo(`/invite/${orgId}/${inviteType}/settings/${inviteId}/${invite.workflowId || ''}`);

  const [modal, openModal] = useModal((type: string, _: any, dismissHandler: () => void) =>
    invite && type === 'code' ? (
      <EasyLinkModal
        link={(invite as EasyLinkInvite).redemptionLink}
        description={`<h4>Use the <b>${invite.name}</b> Easy Link.</h4>`}
        handleDismiss={dismissHandler}
      />
    ) : null
  );

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        {isLoading ? (
          <LoadingIndicator />
        ) : !invite || error ? (
          <ErrorMessage />
        ) : (
          <>
            <HeaderRow>
              <TitleContainer>
                <SettingsHeader
                  icon={<InviteIcon invitationType={invite.type} />}
                  subtitle={InvitationTypeDisplayNameMap[invite.type]}
                  title={invite.name}
                />
                {invite.active === false ? (
                  <span className="invite--deactivated">DEACTIVATED</span>
                ) : (
                  <InlineList>
                    {isEasyLinkInvite(invite) && invite.membershipCode && <li>{invite.membershipCode}</li>}
                    <li>
                      {invite.redemptionCount} of {invite.redemptionLimit ?? 'Unlimited'} Invitations Redeemed
                    </li>
                  </InlineList>
                )}
              </TitleContainer>

              {isEasyLinkInvite(invite) && (
                <TileButton onClick={redirectToEasyLinkSettings}>
                  <i className="fas fa-pencil-alt"></i> Edit Settings
                </TileButton>
              )}
            </HeaderRow>

            <OptionsContainer>
              {isEmailInvite(invite) ? (
                <>
                  <PrimaryButton onClick={() => windowService.redirectTo(`/invite/${orgId}/send-emails/${inviteId}`)}>
                    Add Recipients
                  </PrimaryButton>

                  <SelectContainer>
                    <Label>Status</Label>
                    <Select
                      value={filters.status.value}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => filters.status.update(e.target.value)}
                    >
                      <option value="">All Statuses</option>
                      {Statuses.map(status => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </Select>
                  </SelectContainer>
                </>
              ) : (
                <WhiteButton onClick={() => openModal(invite.type)}>
                  <i className="fas fa-share-square"></i> Share Easy Link
                </WhiteButton>
              )}

              <TextFilterInput
                aria-label="Search Redemptions"
                placeholder="Search Redemptions"
                value={filters.query.value}
                onChangeHandler={(e: ChangeEvent<HTMLInputElement>) => filters.query.update(e.target.value)}
                clearInput={() => filters.query.update('')}
              />
            </OptionsContainer>

            {recipients?.length === 0 ? (
              // Easy Links are the only type that can have no recipients
              <EmptyState title="No Redemptions" description="Redeemed Easy Links will show up here" />
            ) : filteredRecipients?.length > 0 ? (
              <RecipientList>
                {filteredRecipients?.map((recipient: Invitee | Redemption) => (
                  <RecipientListItem
                    key={recipient.email}
                    inviteId={invite.id}
                    orgId={invite.orgId}
                    recipient={recipient}
                  />
                ))}
              </RecipientList>
            ) : (
              <EmptyState
                icon="fa-search"
                title="Your search does not have any matches"
                description="Please try another search"
              />
            )}
          </>
        )}
      </PageContainer>
      {modal}
    </>
  );
};
