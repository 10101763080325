import { PartialSearchResultItem } from '../../search';

export type InviteRole = {
  id: { id: string };
  name: string;
};

export type InviteGroup = {
  id: { id: string };
  name: string;
};

export type InviteTraining = {
  id: string;
  name: string;
  _type: string;
  active: boolean;
  trainingId: string;
  taskListId?: { id: string };
  workflowId?: { id: string };
};

export class InviteSettings {
  constructor(
    public readonly roles: InviteRole[] = [],
    public readonly groups: InviteGroup[] = [],
    public readonly trainings: InviteTraining[] = [],
    public readonly licenses: never[] = []
  ) {}
}

export const transformInviteTrainingToSearchResultItem = (training: InviteTraining): PartialSearchResultItem => {
  const isCourse = training._type.toLowerCase() === 'tasklist';
  return {
    id: training.id,
    name: training.name,
    trainingType: isCourse ? 'Tasklist' : 'Workflow',
  };
};

export const transformSearchResultItemToInviteTraining = (training: PartialSearchResultItem): InviteTraining => {
  const isCourse = training.trainingType.toLowerCase() === 'tasklist';
  return {
    id: training.id,
    name: training.name,
    _type: isCourse ? 'TaskList' : 'Workflow',
    trainingId: training.id,
    active: true,
    [isCourse ? 'taskListId' : 'workflowId']: { id: training.id },
  };
};

type RoleWorkflowEntity = {
  _type: 'RoleView';
  roleId: { id: string };
  name: string;
};

type GroupWorkflowEntity = {
  _type: 'GroupView';
  groupId: { id: string };
  name: string;
};

type TrainingWorkflowEntity = {
  _type: 'Assign';
  id: string;
  trainingName: string;
  // The BE returns different structures for tasklists and workflows
  training: { id: { taskListId?: { id: string }; workflowId?: string } };
};

export type WorkflowInviteEntity = RoleWorkflowEntity | GroupWorkflowEntity | TrainingWorkflowEntity;

export const transformRoleEntity = (entity: RoleWorkflowEntity): InviteRole => ({
  id: { id: entity.roleId.id },
  name: entity.name,
});

export const transformGroupEntity = (entity: GroupWorkflowEntity): InviteGroup => ({
  id: { id: entity.groupId.id },
  name: entity.name,
});

export const transformTrainingEntity = (entity: TrainingWorkflowEntity): InviteTraining => {
  return {
    id: entity.training.id.taskListId?.id || entity.training.id.workflowId || '',
    trainingId: entity.training.id.taskListId?.id || entity.training.id.workflowId || '',
    name: entity.trainingName,
    _type: entity.training.id.taskListId ? 'TaskList' : 'Workflow',
    taskListId: entity.training.id.taskListId,
    workflowId: entity.training.id.workflowId ? { id: entity.training.id.workflowId } : undefined,
    active: true,
  };
};
