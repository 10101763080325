import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import ErrorMessage from '../components/ErrorMessage';
import { Container, PageTitle } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import windowService from '../services/windowService';
import { EasyLinkSettingsForm } from './components/EasyLinkSettingsForm';
import { EmailSettingsForm } from './components/EmailSettingsForm';
import { SettingSection } from './components/SettingSection';
import { useInviteSettingsForm } from './hooks/useInviteSettingsForm';
import { InviteUrlParams } from './models/inviteUrlParams';

export const InviteSettings = () => {
  const { inviteType, inviteId, orgId, settingsId } = useParams<InviteUrlParams>();
  const { isLoading, error } = useInviteSettingsForm(orgId, inviteId || '');

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    { name: 'Settings' },
  ];

  const isEmail = inviteType === 'email';
  const SettingsForm = isEmail ? EmailSettingsForm : EasyLinkSettingsForm;

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container>
        <PageTitle className="mt-32 mb-40">
          <h1>
            {inviteId ? 'Edit' : 'Add'} {isEmail ? 'Email' : 'Easy Link'} Invitation
          </h1>
          {isEmail ? (
            <p>Create an email invitation. Recipients can be added on the next page.</p>
          ) : (
            <p>{inviteId ? 'Edit your' : 'Create an'} Easy Link invitation that can be shared with anyone</p>
          )}
        </PageTitle>

        {isLoading ? (
          <LoadingState />
        ) : !!error ? (
          <ErrorMessage />
        ) : (
          <SettingsForm>
            <PageTitle className="mb-32">
              <h4>Automatic Assignments</h4>
              {inviteId ? (
                <p>
                  View the roles, groups, and training attached to this invitation. Only training is editable once the
                  invitation is created
                </p>
              ) : (
                <p>
                  Select the roles, groups, and training that you want automaticaly assigned when this invitation is
                  accepted
                </p>
              )}
            </PageTitle>
            <SettingSection
              key="roles"
              title="Roles"
              valueKey="roles"
              canEdit={!inviteId}
              redirectToAdd={() => {
                windowService.redirectTo(`/invite/${orgId}/${inviteType}/add-roles`);
              }}
            />
            <SettingSection
              key="groups"
              title="Groups"
              valueKey="groups"
              canEdit={!inviteId}
              redirectToAdd={() => {
                windowService.redirectTo(`/invite/${orgId}/${inviteType}/add-groups`);
              }}
            />
            <SettingSection
              key="trainings"
              title="Training"
              valueKey="trainings"
              canEdit={true}
              redirectToAdd={() => {
                windowService.redirectTo(
                  `/invite/${orgId}/${inviteType}/add-training${inviteId ? `/${inviteId}` : ''}${
                    settingsId ? `/${settingsId}` : ''
                  }`
                );
              }}
            />
          </SettingsForm>
        )}
      </Container>
    </>
  );
};
