import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import ErrorMessage from '../components/ErrorMessage';
import { Container, PageTitle } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import useOrg from '../hooks/useOrg';
import windowService from '../services/windowService';
import { localStorage } from '../utils/storageUtils';
import { AddSetting } from './components/AddSetting';
import { InviteRole } from './models/inviteSettings';
import { InviteUrlParams } from './models/inviteUrlParams';
import { updateStoredInvite } from './state/storedInviteState';

type OrgStatus = {
  org?: { roles: OrgRole[] };
  isLoading: boolean;
  error?: Error;
};

type OrgRole = InviteRole & { active: boolean };

export const AddRolesToInvite = () => {
  const [{ org, isLoading, error }, setOrgStatus] = useState<OrgStatus>({ isLoading: true });
  const { orgId, inviteType } = useParams<InviteUrlParams>();

  useOrg(setOrgStatus);

  const inviteSettingsPath = `#/invite/${orgId}/${inviteType}/settings`;

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    { name: 'Invite Settings', route: inviteSettingsPath },
    { name: 'Add Roles' },
  ];

  const onRolesSelected = (selectedItems: InviteRole[]) => {
    updateStoredInvite(invite => ({ ...invite, roles: selectedItems }));
    windowService.redirectTo(inviteSettingsPath);
  };

  return (
    <div className="mb-64">
      <Breadcrumbs crumbs={crumbs} />

      <Container>
        <PageTitle className="mt-32 mb-32">
          <h1>Add Roles to Invite</h1>
          <p>Select the roles you would like to attach to your invite</p>
        </PageTitle>

        {isLoading ? (
          <LoadingState />
        ) : error || !org ? (
          <ErrorMessage />
        ) : (
          <AddSetting
            settingLabel="Roles"
            items={org?.roles.filter(role => role.active !== false) || []}
            preselectedItems={localStorage.getItem('ngStorage-inviteRoles') || []}
            onSubmit={onRolesSelected}
          />
        )}
      </Container>
    </div>
  );
};
