import { Form } from 'formik';
import styled from 'styled-components';
import { Breakpoints, Color, Grid } from '../../StyleGuide';
import { FormField, FormFieldContainer } from '../../components/FormElements';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${Grid._4};
  }

  > hr {
    border-top: 2px solid ${Color.Gray._20};
    margin: ${Grid._5} 0;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;

    button {
      width: auto;
    }

    > div {
      flex-direction: row;
    }

    > hr {
      display: none;
    }
  }
`;

export const InviteForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Grid._13};
`;

export const ToggleInputGroup = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    height: 46px;
    margin-left: ${Grid._4};
  }
`;

export const InviteNameFormFieldContainer = styled(FormFieldContainer)`
  max-width: 600px;
`;

export const InputOptionsContainer = styled.div`
  display: flex;
  column-gap: ${Grid._10};
  row-gap: ${Grid._6};
  flex-wrap: wrap;
  margin-bottom: ${Grid._10};

  ${FormFieldContainer} {
    margin-bottom: 0;
  }
`;
