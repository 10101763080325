import submitCommand from '../../services/submitCommand';

type SetRedemptionLimitRequest = {
  id: string;
  initiatingUserId: string;
  redemptionLimit?: number;
};

export const setRedemptionLimitToUnlimited = (inviteId: string, initiatingUserId: string) =>
  updateRedemptionLimit({ id: inviteId, initiatingUserId });
export const setRedemptionLimit = (inviteId: string, redemptionLimit: number, initiatingUserId: string) =>
  updateRedemptionLimit({ id: inviteId, redemptionLimit, initiatingUserId });

const updateRedemptionLimit = (requestbody: SetRedemptionLimitRequest) =>
  submitCommand(
    requestbody.id,
    requestbody,
    'SetMembershipCodeRedemptionLimit',
    'MembershipCodeRedemptionLimitSet',
    'MembershipCodeError'
  );
